import React, {createContext, useEffect} from 'react';

const rigthArrayBoolean = [
    [false, false, false, false, false, false, false, false, true],
    [false, true, true, true, false, false, false, false, false],
    [false, true, false, false, false, true, false, false, true],
    [true, false, false, true, false, false, false, true, false],
    [false, true, false, true, true, false, true, false, false],
    [false, false, false, false, false, true, true, true, false],
    [true, false, false, true, false, true, false, false, false],
    [false, false, false, false, false, false, false, false, true],
    [false, false, false, false, true, false, false, true, false],
];

export const MyContext = createContext(rigthArrayBoolean);

const MyContextProvider = ({ children }) => {


    return (
        <MyContext.Provider value={rigthArrayBoolean}>
            {children}
        </MyContext.Provider>
    );
}

export default MyContextProvider;
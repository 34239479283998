import './App.css';
import {createContext, useEffect, useState} from "react";
import Input from "./input.component";
import Context from "./Context";

function App(defaultValue) {

    const [size, setSize] = useState(9);
    const [matrix, setMatrix] = useState([]);
    const [rightMatrix, setRightMatrix] = useState([]);

    useEffect(() => {
        const array = [
                [0, 0, 0, 0, 0, 0, 0, 0, 8],
                [0, 9, 8, 2, 0, 0, 0, 0, 0],
                [0, 5, 0, 0, 0, 6, 0, 0, 2],
                [4, 0, 0, 7, 0, 0, 0, 6, 0],
                [0, 6, 0, 9, 4, 0, 8, 0, 0],
                [0, 0, 0, 0, 0, 2, 5, 3, 0],
                [1, 0, 0, 4, 0, 3, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 6],
                [0, 0, 0, 0, 6, 0, 0, 5, 0],
        ];
        const rigthArray = [
            [2, 1, 3, 5, 9, 4, 6, 7, 8],
            [6, 9, 8, 2, 7, 1, 3, 4, 5],
            [7, 5, 4, 3, 8, 6, 1, 9, 2],
            [4, 2, 5, 7, 3, 8, 9, 6, 1],
            [3, 6, 1, 9, 4, 5, 8, 2, 7],
            [9, 8, 7, 6, 1, 2, 5, 3, 4],
            [1, 7, 6, 4, 5, 3, 2, 8, 9],
            [5, 3, 9, 8, 2, 7, 4, 1, 6],
            [8, 4, 2, 1, 6, 9, 7, 5, 3],
        ];
        setMatrix(array);
        setRightMatrix(rigthArray)
    }, [size]);
    const [count, setCount] = useState(1)

    const handleClick = () => {
        console.log(count)
        setCount(count + 1)
    }

  return (
    <div className={"center"} onClick={handleClick}>
      <h1>
          {count >= 5 &&
              <>
                  <p>
                      digit sum - description
                  </p>
                  <a href={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} target={"_blank"}>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a>
              </>
          }
          {count >= 19 &&
              <Context>
                  <div>
                      {matrix.map((row, i) => (
                          <div key={i}>
                              {row.map((cell, j) => (
                                  <Input key={j} value={cell} rightNumber={rightMatrix[i][j]} positionX={i}
                                         positionY={j}></Input>
                              ))}
                          </div>
                      ))}
                  </div>
              </Context>
          }
      </h1>
    </div>
  )
      ;
}

export default App;

import {useContext, useEffect} from "react";
import Context, {MyContext} from "./Context";

const Input = ({value, rightNumber, positionX, positionY}) => {

    const rightArrayBoolean = useContext(MyContext)

    const checkNumber = (e) => {

        if (parseInt(e.target.value, 10) === rightNumber) {
            rightArrayBoolean[positionX][positionY] = true;

            const allTrue = rightArrayBoolean.every(row => row.every(value => value));
            if (allTrue) {
                alert('All values are true! Here is the link:');
            }
        } else {
            rightArrayBoolean[positionX][positionY] = false;
        }

    }

    return (
        <input type="text" id={"input-field" + positionX + " " + positionY} defaultValue={value} onInputCapture={checkNumber}/>
    )
}

export default Input;